import { IPlayerResponse, IPlayerUpdate } from "@/interfaces/api/IPlayer";
import Setup from "@/helpers/Setup";
import Helper from "@/helpers/CommonFunctions";
import axios, { AxiosResponse } from "axios";
import CustomerHelper from "@/helpers/CustomerHelper";
import ErrorHandling from "@/helpers/ErrorHandling";
import merge from 'lodash/merge'
import { encodeURI } from 'js-base64';

/**
 * APIModule
 * This handles the api requests
 */
class APIModule {
    private loggedIn = false;
    private registerFlow = false;
    private playerData: any;
    private token = '';

    /**
     * Validate the user in case logged in
     */
    async validateUser($route: any) {
        const registerFlow = Setup.getValue("settings.flow.register");

        // We have a token, try to log in
        if (this.getTokenFromStorage() && !$route.query.token && registerFlow != 'native') {
            try {
                this.playerData = await this.getPlayer();
                if (this.playerData && this.playerData.id) {
                    this.loggedIn = true;
                }
            } catch (e) {
                console.log("Login data incorrect");
            }
        }
        if (this.loggedIn) {
            return true;
        }

        // We are native, try to log in
        if (registerFlow == 'native') {
            await CustomerHelper.handleAsyncRequest({ type: 'login' });
        }
        // We can create an anonymous account. Try to do that. 
        else if (registerFlow == 'anonymous') {
            let token = $route.query.token;
            if (!token) {
                const rand = () => Math.random().toString(36).substr(2);
                const tokenGenerate = (length: integer) => (rand() + rand() + rand() + rand()).substr(0, length);
                token = tokenGenerate(20);
            }
            try {
                await this.registerAnonymousPlayer(token);
            } catch (e) {
                ErrorHandling.triggerError("There was an error registering your user.", e);
            }
        }
        // We have a regular flow with registration
        else {
            this.registerFlow = true;
        }
    }

    /**
    * Register player on db - logs in if user exists and returns token
    * @param player player object from form
    */
    async registerPlayer(player: any): Promise<IPlayerResponse> {
        const token = this.getTokenFromStorage() as string;
        if (this.loggedIn && token && token.length > 15) {
            this.playerData = await this.getPlayer();
            return this.playerData;
        }
        player.language = Setup.getLanguage();
        player.gameId = Setup.getGameId();

        //Get token from email
        const result = await this.request("POST", "player/register", player);
        this.setTokenInStorage(result.data.token);
        this.loggedIn = true;

        this.playerData = await this.getPlayer();
        return this.playerData;
    }

    /**
    * Register anonymous player on db - logs in if user exists and returns token
    * @param player player object from form
    */
    async registerAnonymousPlayer(identifier: any): Promise<IPlayerResponse> {
        const player: any = {};
        player.language = Setup.getLanguage();
        player.gameId = Setup.getGameId();
        player.terms = true;
        player.identifier = identifier;

        // Get token from email    
        const result = await this.request("POST", "player/registerAnonymous", { ...player });
        this.setTokenInStorage(result.data.token);
        this.loggedIn = true;

        this.playerData = await this.getPlayer();
        return this.playerData;
    }

    /**
     * Get player data
     * @param data the data to update user with
     */
    async getPlayer(): Promise<IPlayerResponse> {
        const { data } = await this.request("GET", `player?gameId=${Setup.getGameId()}`);
        return data.player;
    }

    /**
      * Logs in player and returns token
      * @param data Object with email and password
      */
    async loginPlayer(data: any): Promise<void> {
        const result = await this.request("POST", "player/login", { ...data, gameId: Setup.getGameId() });
        if (!result.data || !result.data.token) {
            throw new Error("Login failed");
        }
        this.setTokenInStorage(result.data.token);
        this.loggedIn = true;
    }

    /**
      * Logs off player
      */
    async logoffPlayer(): Promise<void> {
        await this.request("PUT", "player/logout", { gameId: Setup.getGameId() });
        localStorage.removeItem('token');
        localStorage.removeItem('token_' + Setup.getGameId());
        this.loggedIn = false;
    }

    /**
      * Logs in player and returns token
      * @param data Object with email and password
      */
    async passwordRequest(data: any): Promise<void> {
        await this.request("POST", "player/requestPasswordReset", { ...data, gameId: Setup.getGameId(), language: Setup.getLanguage() });
    }

    /**
      * Reset password with existing token
      * @param data Object with email and password
      */
    async passwordReset(data: any): Promise<void> {
        await this.request("POST", "player/resetPassword", { ...data, gameId: Setup.getGameId(), language: Setup.getLanguage() });
    }

    /**
     * Update player information
     * @param data 
     */
    async updatePlayer(data: IPlayerUpdate) {
        this.playerData = merge(this.playerData, data);
        await this.request("PUT", `player`, data);
    }

    /**
     * Get current player data
     */
    getPlayerData(): any {
        return this.playerData;
    }

    /**
     * Call used to initiate a score/game session
     * @param level the level of the current session
     * @returns response return response body, which includes a score token used for the endGame call (expires in 8 hours)
     */
    beginGame(level: number) {
        try {
            return this.request("POST", "score", {
                level: level,
                gameId: Setup.getGameId(),
                startTime: Helper.getAPIDate()
            });
        } catch (e) {
            ErrorHandling.triggerError("There was an error starting your game. ", e);
        }
    }

    /**
     * Call used to finish a game session
     * @param token the token given by the beginGame call (can only be used once)
     * @param score the score of just finished game session
     * @param gameData optional but recommended to provide - should include data to recalculate the score value server-side
     * @returns response response has no real use
     */
    saveScore(id: number, score: number, level: number, gameData?: any) {
        try {
            return this.request("PUT", `score/${id}`, {
                score: score,
                level: level,
                gameId: Setup.getGameId(),
                endTime: Helper.getAPIDate(),
                ...(gameData ? { gameData: gameData } : {})
            });
        } catch (e) {
            ErrorHandling.triggerError("There was an error saving your game. ", e);
        }
    }

    /**
     * Get all available products
     * @returns response list of all available products
     */
    getProductList() {
        return this.request("GET", `prize/list?gameId=${Setup.getGameId()}&type=product`);
    }

    /**
     * Buy a product by ID
     * @param id the prize ID, provided by getProductList
     * @param mobileNumber the number to send the purchased coupon to
     * @returns response response has no real use
     */
    buyProduct(id: number, mobileNumber: string) {
        return this.request("POST", `prize/buy/${id}`, { mobileNumber: mobileNumber, gameId: Setup.getGameId() });
    }

    /**
    * Get all available prizes
    * @returns response list of all available prizes
    */
    getPrizeList() {
        return this.request("GET", `prize/list?gameId=${Setup.getGameId()}`);
    }

    /**
     * Claim a prize ID, this is used for client-based prize claiming and will be marked as such
     * @param id the prize ID, provided by getPrizeList
     * @returns response response has no real use
     */
    claimPrize(id: number) {
        return this.request("POST", `prize/claim/${id}`, { gameId: Setup.getGameId() });
    }

    /**
     * Get all prizes you have won
     * @param claimed whether you want to retrieve all your claimed prizes, or unclaimed prizes
     * @returns response list of all claimed/unclaimed prizes
     */
    getPersonalPrizeList(claimed = false) {
        return this.request("GET", `prize/list/personal?gameId=${Setup.getGameId()}&claimed=${claimed ? 1 : 0}`);
    }

    /**
     * Subtract currency from your account - mainly used currently for logging purposes
     * @param amount the amount of current you want to spend (must be positive)
     * @returns response response has no real use
     */
    subtractCurrency(amount: number) {
        return this.request("POST", "event/currency", { currency: -amount });
    }

    /**
     * Get leaderboard by provided type
     * @param type the type of leaderboard you want (daily, weekly, total), note however that the type must be enabled by server
     * @returns response list of top 10 and your personal ranking if you're not in the top 10
     */
    getLeaderboard(type: string, level = -1) {
        return this.request("GET", `score/leaderboard/${type}`, {
            gameId: Setup.getGameId(),
            ...(level !== -1 ? { level: level } : {})
        });
    }

    /**
     * Return wether the user is logged in
     */
    isLoggedIn() {
        return this.loggedIn;
    }

    /**
     * Get register flow
     */
    getRegisterFlow() {
        return this.registerFlow;
    }

    /**
     * Remove the token
     */
    resetToken() {
        this.token = '';
    }

    /**
     * Request function
     * This handles the requests to the URL
     * @param method 
     * @param path 
     * @param data 
     * @param noAuth 
     */
    private async request(method: string, path: string, data?: any, noAuth?: boolean) {
        const normalizedMethod = method.toLowerCase();
        let result: AxiosResponse | null = null;

        // Get token
        let token: string | undefined;
        if (!noAuth) {
            token = this.getTokenFromStorage() as string;
        }

        if (data && data.token) {
            token = data.token;
            delete data.token;
        }

        // Data to base64
        // if(data && method !== "GET") {
        //     data = encodeURI(JSON.stringify(data));
        // }

        // Do request
        result = await axios(`${process.env.VUE_APP_API_URL}/${path}`, {
            method: normalizedMethod as any,

            // Set data
            ...(normalizedMethod === "get" && data ? { params: data } : {}),
            ...(normalizedMethod !== "get" && data ? { data: data } : {}),

            // Set headers
            headers: {
                ...(noAuth ? {} : { "Authorization": `Bearer ${token}` }),
                // ...(data && method!=='GET' ? { "mask-request": true, "Content-Type": "text/plain"  } : {})
            }
        });

        // Return result
        return result ? result.data : null;
    }


    /**
     * Get token from storage
     */
    private getTokenFromStorage(): any {
        if (localStorage && localStorage.getItem('token_' + Setup.getGameId())) {
            return localStorage.getItem('token_' + Setup.getGameId());
        }
        else if (localStorage && localStorage.getItem('token')) {
            return localStorage.getItem('token');
        }
        else {
            return this.token;
        }
    }

    /**
     * Set token
     */
    private setTokenInStorage(token: string) {
        if (localStorage) {
            localStorage.setItem('token_' + Setup.getGameId(), token);
        }
        this.token = token;
    }

}

const apiModule = new APIModule();
export default apiModule;